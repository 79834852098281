import { http } from './config'
import authHeader from './auth-header';

export default {


       

    ranking: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

            
        return http.get('/acordos-dash/lista-ranking/', {
        params: params,
        headers: authHeader()   
        })  
        },


    ranking_cancelados: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        

        return http.get('/acordos-dash/lista-ranking-cancelados/', {
        params: params,
        headers: authHeader()   
        })  
        },
        

       


    total_recebimento_acordo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/acordos-dash/total-recebimento-acordo/', {
            params: params,
            headers: authHeader()
        })        
    },


    total_cadastrado_acordo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/acordos-dash/total-cadastrado-acordo/', {
            params: params,
            headers: authHeader()
        })        
    },


    


    qtd_recebimento_acordo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/acordos-dash/qtd-recebimento-acordo/', {
            params: params,
            headers: authHeader()
        })        
    },
    

    qtd_criado_acordo: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/acordos-dash/qtd-criado-acordo/', {
            params: params,
            headers: authHeader()
        })        
    },

    qtd_cancelados_acordos: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {
        
        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }
        return http.get('/acordos-dash/qtd-cancelados-acordos/', {
            params: params,
            headers: authHeader()
        })        
    },


    

}
